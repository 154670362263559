<template>
    <div class="row">
        <div class="col-12 mb-1">
            <label class="form-label">Bölge Kodu</label>
            <input type="text" class="form-control" v-model="value.RegionCode" placeholder="Bölge Kodu" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Bölge Adı</label>
            <input type="text" class="form-control" v-model="value.RegionName" placeholder="Bölge Adı" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Açıklama</label>
            <input type="text" class="form-control" v-model="value.RegionDescription" placeholder="Açıklama" />
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    },
    data(){
        return{
           
        }
    },
}
</script>

<style>

</style>